// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-bodykom-js": () => import("./../../../src/pages/bodykom.js" /* webpackChunkName: "component---src-pages-bodykom-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investor-relations-js": () => import("./../../../src/pages/investorRelations.js" /* webpackChunkName: "component---src-pages-investor-relations-js" */),
  "component---src-pages-nyemission-js": () => import("./../../../src/pages/nyemission.js" /* webpackChunkName: "component---src-pages-nyemission-js" */),
  "component---src-templates-ir-post-js": () => import("./../../../src/templates/irPost.js" /* webpackChunkName: "component---src-templates-ir-post-js" */)
}

